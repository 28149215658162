import * as React from 'react';
import { Button, Dropdown, DropdownList, MenuToggle, MenuToggleElement } from '@patternfly/react-core';
import BellIcon from '@patternfly/react-icons/dist/esm/icons/bell-icon';
import CogIcon from '@patternfly/react-icons/dist/esm/icons/cog-icon';
import QuestionIcon from '@patternfly/react-icons/dist/esm/icons/question-circle-icon';
import { UsernameAvatar } from './UsernameAvatar';
import { useContext } from 'react';
import { DataContext } from '@app/DataContext';


const NavActionButtons: React.FunctionComponent = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const onToggleClick = () => {
    setIsOpen(!isOpen);
  };

  const onSelect = (_event: React.MouseEvent<Element, MouseEvent> | undefined, value: string | number | undefined) => {
    // eslint-disable-next-line no-console
    console.log('selected', value);
    setIsOpen(false);
  };

  const { notifications } = useContext(DataContext);

  React.useEffect(() => {
    console.log('open new notification');
    if (notifications.length > 0) {
      setIsOpen(true);
    }
  }, [notifications])

  return (
    <div className="nav-right-part">

      <UsernameAvatar></UsernameAvatar>

      <Button variant="plain" aria-label="Action">
        <QuestionIcon />
      </Button>

      <Button variant="plain" aria-label="Action">
        <CogIcon />
      </Button>

      <Dropdown
        isOpen={isOpen}
        onSelect={onSelect}
        onOpenChange={(isOpen: boolean) => setIsOpen(isOpen)}
        toggle={(toggleRef: React.Ref<MenuToggleElement>) => (
          <MenuToggle
            ref={toggleRef}
            aria-label="kebab dropdown toggle"
            variant="plain"
            onClick={onToggleClick}
            isExpanded={isOpen}
          >
            <BellIcon />
          </MenuToggle>
        )}
        shouldFocusToggleOnSelect
      >
        <DropdownList className='notification-drawer break-lines'>
          {notifications.map((label, rowIndex) => {
            if (notifications.length === rowIndex + 1) {
              return <div key={rowIndex}>{label}</div>
            } else {
              return <div key={rowIndex} className='notification-item'>{label}</div>
            }

          }
          )}
          {notifications.length === 0 &&
            <div>No new notifications.</div>
          }
        </DropdownList>
      </Dropdown>

      {notifications.length > 0 &&
        <div className='notification-counter' onClick={() => setIsOpen(!isOpen)}>
          <div>{notifications.length}</div>
        </div>
      }


    </div>
  )
}


export { NavActionButtons };
