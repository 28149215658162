import * as React from 'react';
import SearchIcon from '@patternfly/react-icons/dist/esm/icons/search-icon';
import { TextInput } from '@patternfly/react-core';

const SearchAll: React.FunctionComponent = () => {
  const [value, setValue] = React.useState('');


  return (
    <div className='search-input'>
      <TextInput
        value={value}
        type="text"
        customIcon={<SearchIcon />}
        placeholder="Search in all environments"
        onChange={(_event, value) => setValue(value)}
        aria-label="text input example"
      />
    </div>

  )
}


export { SearchAll };
