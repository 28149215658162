import * as React from 'react';
import HDDIcon from '@patternfly/react-icons/dist/esm/icons/hdd-icon';


import {  TreeView, TreeViewDataItem } from '@patternfly/react-core';
import { backendURL } from '@app/TreeContainer/BackendURL';

export interface TreeViewProps {
  onSelect?: (event: React.MouseEvent, item: TreeViewDataItem, parentItem: TreeViewDataItem) => void;
  /** Callback for expanding a node with children. */
}
export const VolumeTreeViewImpl: React.FunctionComponent<TreeViewProps> = ({onSelect}) => {
  const [options, setOptions] = React.useState([{
    name: 'Loading...',
    id: 'loading',
    children: []
  }]);
  React.useEffect(() => {
    fetch(`${backendURL}/api/volume/demo/tree`)
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                console.log(data);
                // options = data;
                setOptions(data);
            })

    return () => {    };
  }, []);


  return (
    <React.Fragment>
      <TreeView data={options} onSelect={onSelect} allExpanded={true} icon={<HDDIcon />}
      expandedIcon={<HDDIcon />}></TreeView>
    </React.Fragment>
  );
};

