import { backendURL } from '@app/TreeContainer/BackendURL';
import * as React from 'react';
declare const moment: any;
import { ExpandableRowContent, Table, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import {  Button, Popper, TextInput, Toolbar, ToolbarContent, ToolbarToggleGroup } from '@patternfly/react-core';
import { FilterIcon, TrashIcon } from '@patternfly/react-icons';
import { GreenCircle } from '@app/TreeContainer/indicators/GreenCircle';

export interface Label {
  value: string,
}


export interface Deployment {
  name: string;
  labels: Label[];
  replicas: number;
  updated: number;
  ready: number
  available: number;
}


export interface Port {
  targetPort: string,
  nodePort: string,
  port: string,
  protocol: string,
  name: string

}

export interface Ingress {
  value: string
}


export interface Service {
  name: string;
  labels: Label[];
  ports: Port[];
  ingresses: Ingress[];
  creationTimestamp: string;
}

export interface Job {
  name: string;
  labels: Label[];
  completionTime: string;
  command: string;
  image: string
  startTime: string;
  terminationMessagePath: string;
}

// status.startTime
// status.completionTime
// spec.containers[0].command
// spec.containers[0].image
// spec.containers[0].terminationMessagePath


export const SystemJobView: React.FunctionComponent = () => {
  const [jobs, setJobs] = React.useState<Job[]>([]);
  const [filteredJobs, setFilteredJobs] = React.useState<Job[]>([]);

  const defaultState = { items: [] };
  const servicesType: Service[] = [];
  const [services, setServices] = React.useState(defaultState);
  const [defaultServices, setDefaultServices] = React.useState(servicesType);
  const [filteredServices, setFilteredServices] = React.useState(servicesType);

  const deploymentType: Deployment[] = [];
  const [defaultDeployments, setDefaultDeployments] = React.useState<Deployment[]>(deploymentType);
  const [filteredDeployments, setFilteredDeployments] = React.useState<Deployment[]>(deploymentType);

  const getPods = () => {

    const url = `${backendURL}/api/system/demo/jobs`;
    const response = fetch(url)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const jobList: Job[] = [];
        data.items.forEach((item) => {
          const job: Job = {
            name: item.metadata.name,
            labels: [],
            startTime: item.status.startTime,
            completionTime: item.status.completionTime,
            image: item.spec.template.spec.containers[0].image,
            command: item.spec.template.spec.containers[0].command.join(' '),
            terminationMessagePath: item.spec.template.spec.containers[0].terminationMessagePath,
          };
          const labelList: Label[] = [];
          for (const key in item.metadata.labels) {
            // Get the strongly typed value with this name:
            const _value = item.metadata.labels[key];
            const labelItem: Label = {
              value: `${key}: ${_value}`
            };
            labelList.push(labelItem);
          }
          job.labels = labelList;
          jobList.push(job);
          console.log('jobs', jobList);
          setJobs(jobList);
          setFilteredJobs(jobList);
        })

      })


  }

  React.useEffect(() => {
    getPods();
  }, [])

  const columnNames = {
    name: 'Job',
    labels: 'Labels',
    status: 'Status',
    replicas: 'Start',
    ready: 'Completion',
  };

  const [isOpen, setIsOpen] = React.useState(false);

  const onToggleClick = () => {
    setIsOpen(!isOpen);
  };

  const [locationSelections, setLocationSelections] = React.useState<string[]>([]);
  const [filterOptions, setFilterOptions] = React.useState<string[]>(['Running', 'Pending']);
  const [statusSelections, setStatusSelections] = React.useState<string[]>(['Running', 'Pending']);
  const [hostsSelections, setHostsSelections] = React.useState<string[]>([]);


  const areSelectionsPresent = locationSelections.length > 0 || statusSelections.length > 0 || hostsSelections.length > 0;
  const toggleRef = React.useRef<HTMLButtonElement>(null);
  const toggle = (
    <></>
  );

  const _isSelected = (_type) => {
    return statusSelections.includes(_type)
  }

  const menuRef = React.useRef<HTMLDivElement>(null);
  const menu = (
    <></>
  );

  const isRepoSelectable = (repo: Job) => repo.name !== 'a';
  const selectableRepos = jobs.filter(isRepoSelectable);
  const [selectedRepoNames, setSelectedRepoNames] = React.useState<string[]>([]);
  const setRepoSelected = (repo: Job, isSelecting = true) =>
    setSelectedRepoNames((prevSelected) => {
      const otherSelectedRepoNames = prevSelected.filter((r) => r !== repo.name);
      return isSelecting && isRepoSelectable(repo) ? [...otherSelectedRepoNames, repo.name] : otherSelectedRepoNames;
    });
  const selectAllRepos = (isSelecting = true) =>
    setSelectedRepoNames(isSelecting ? selectableRepos.map((r) => r.name) : []);
  const areAllReposSelected = selectedRepoNames.length === selectableRepos.length;

  const containerRef = React.useRef<HTMLDivElement>(null);
  const select = (
    <div ref={containerRef}>
      <Popper
        trigger={toggle}
        triggerRef={toggleRef}
        popper={menu}
        popperRef={menuRef}
        appendTo={containerRef.current || undefined}
        isVisible={isOpen}
      />
    </div>
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (isOpen && !menuRef.current?.contains(event.target as Node) && !toggleRef.current?.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen, menuRef]);

  const onChipDelete = (category: string, chip: string) => {
    if (category === 'status') {
      setStatusSelections(statusSelections.filter((selection) => selection !== chip));
    } else {
      setLocationSelections(locationSelections.filter((selection) => selection !== chip));
    }
  };

  const toolbar = (
    <Toolbar
      id="filter-faceted-toolbar"
      clearAllFilters={() => {
      }}
    >
      <ToolbarContent>
        <ToolbarToggleGroup toggleIcon={<FilterIcon />} breakpoint="xl">
          {/* <ToolbarFilter
            chips={statusSelections}
            deleteChip={(category, chip) => onChipDelete(category as string, chip as string)}
            deleteChipGroup={() => setStatusSelections([])}
            categoryName="Status"
            showToolbarItem={false}
          >
            <div />
          </ToolbarFilter> */}
          {/* <ToolbarFilter
            chips={locationSelections}
            deleteChip={(category, chip) => onChipDelete(category as string, chip as string)}
            deleteChipGroup={() => setLocationSelections([])}
            categoryName="Labels"
          > */}
          {select}
          {/* </ToolbarFilter> */}
        </ToolbarToggleGroup>
      </ToolbarContent>
    </Toolbar>
  );

  const [value, setValue] = React.useState('');

  const initialExpandedRepoNames = []; // Default to all expanded
  const [expandedRepoNames, setExpandedRepoNames] = React.useState<string[]>(initialExpandedRepoNames);
  const setRepoExpanded = (service: Job, isExpanding = true) =>
    setExpandedRepoNames((prevExpanded) => {
      const otherExpandedRepoNames = prevExpanded.filter((r) => r !== service.name);
      return isExpanding ? [...otherExpandedRepoNames, service.name] : otherExpandedRepoNames;
    });
  const isServiceExpanded = (repo: Job) => expandedRepoNames.includes(repo.name);



  // selection
  const isRepoSelected = (repo: Job) => selectedRepoNames.includes(repo.name);

  const [shifting, setShifting] = React.useState(false);
  const [recentSelectedRowIndex, setRecentSelectedRowIndex] = React.useState<number | null>(null);

  const onSelectRepo = (repo: Job, rowIndex: number, isSelecting: boolean) => {
    // If the user is shift + selecting the checkboxes, then all intermediate checkboxes should be selected
    if (shifting && recentSelectedRowIndex !== null) {
      const numberSelected = rowIndex - recentSelectedRowIndex;
      const intermediateIndexes =
        numberSelected > 0
          ? Array.from(new Array(numberSelected + 1), (_x, i) => i + recentSelectedRowIndex)
          : Array.from(new Array(Math.abs(numberSelected) + 1), (_x, i) => i + rowIndex);
      intermediateIndexes.forEach((index) => setRepoSelected(jobs[index], isSelecting));
    } else {
      setRepoSelected(repo, isSelecting);
    }
    setRecentSelectedRowIndex(rowIndex);
  };

  const onDeleteClick = () => {
    console.log('selected', selectedRepoNames);
  }


  return (
    <React.Fragment>
      <div className='controls-pane'>

        <div className='top-padding-controls-toolbar'>

          <TextInput
            className='server-name-filter deployment-filter-margin'
            placeholder="Filter by job name"
            value={value}
            type="text"
            onChange={
              (_event, value) => {
                setValue(value);
                const filteredJobs = jobs.filter((item: Job) => {
                  console.log('includes:', item.name.includes(value));
                  return item.name.includes(value);
                })
                setFilteredJobs(filteredJobs);
              }
            }
            aria-label="text input example"
          />

        </div>
        <div className='top-padding-controls-toolbar'>
          <Button onClick={onDeleteClick} variant="plain" aria-label="Action">
            <TrashIcon />
          </Button>

        </div>
      </div>

      <Table
        variant="compact"
        aria-label="Simple table">
        <Thead>
          <Tr>
            <Th
              select={{
                onSelect: (_event, isSelecting) => selectAllRepos(isSelecting),
                isSelected: areAllReposSelected
              }}
              aria-label="Row select"
            />
            <Th />
            <Th>{columnNames.name}</Th>
            <Th>{columnNames.status}</Th>
            <Th>{columnNames.replicas}</Th>
            <Th>{columnNames.ready}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredJobs.map((item: Job, rowIndex) => {
            return <>
              <Tr key={item.name}>
                <Td
                  select={{
                    rowIndex,
                    onSelect: (_event, isSelecting) => onSelectRepo(item, rowIndex, isSelecting),
                    isSelected: isRepoSelected(item),
                    isDisabled: !isRepoSelectable(item)
                  }}
                />
                <Td
                  expand={
                    item.name
                      ? {
                        rowIndex,
                        isExpanded: isServiceExpanded(item),
                        onToggle: () => setRepoExpanded(item, !isServiceExpanded(item)),
                        expandId: 'composable-expandable-example'
                      }
                      : undefined
                  }
                />
                <Td>{item.name}</Td>
                <Td>
                  <div className='status-container'><GreenCircle></GreenCircle> Active</div>
                </Td>
                <Td>{item.startTime}</Td>
                <Td>{item.completionTime}</Td>
              </Tr>
              {item.name ? (
                <>
                  <Tr isExpanded={isServiceExpanded(item)}>
                    <Td dataLabel="Repo detail 0">
                      <ExpandableRowContent>

                      </ExpandableRowContent>
                    </Td>
                    <Td dataLabel="Repo detail 1">
                      <ExpandableRowContent>

                      </ExpandableRowContent>
                    </Td>
                    <Td dataLabel="Repo detail 2">
                      <ExpandableRowContent>
                        <div className='bold-text metadata-title'>Labels</div>
                        {item.labels.map((label, index) => {
                          return <div className='chip-outer-container'><span className='chip-system'>{label.value}</span></div>
                        })}
                        {item.labels.length === 0 && 'No Labels'}
                      </ExpandableRowContent>
                    </Td>
                    <Td dataLabel="Repo detail 3" colSpan={2}>
                      <ExpandableRowContent>
                        <div className='bold-text metadata-title'>Command</div>
                        {item.command}
                      </ExpandableRowContent>
                    </Td>

                  </Tr>

                </>
              ) : null}
            </>
          })}
        </Tbody>
      </Table>

    </React.Fragment>
  );
};