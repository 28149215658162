import { backendURL } from '@app/TreeContainer/BackendURL';
import * as React from 'react';
import { ExpandableRowContent, Table, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import { Button, Popper, TextInput, Toolbar, ToolbarContent, ToolbarToggleGroup } from '@patternfly/react-core';
import { FilterIcon, TrashIcon } from '@patternfly/react-icons';
import { GreenCircle } from '@app/TreeContainer/indicators/GreenCircle';

export interface DaemonSet {
  metadata: {
    name: string;
    namespace: string;
    labels: { [key: string]: string };
    creationTimestamp: string;
  };
  spec: {
    selector: {
      matchLabels: {
        name: string;
      };
    };
    template: {
      spec: {
        containers: {
          name: string;
          image: string;
          resources: {
            limits: { memory: string };
            requests: { cpu: string; memory: string };
          };
        }[];
      };
    };
  };
  status: {
    currentNumberScheduled: number;
    desiredNumberScheduled: number;
    numberReady: number;
  };
}

export interface DaemonSetList {
  items: DaemonSet[];
}


export const SystemDaemonSetView: React.FunctionComponent = () => {
  const [daemonSets, setDaemonSets] = React.useState<DaemonSet[]>([]);
  const [filteredDaemonSets, setFilteredDaemonSets] = React.useState<DaemonSet[]>([]);

  const getDaemonSets = async () => {
    const response = await fetch(`${backendURL}/api/system/demo/daemonsets`);
    const data: DaemonSetList = await response.json();
    setDaemonSets(data.items);
    setFilteredDaemonSets(data.items);
  };


  React.useEffect(() => {
    getDaemonSets();
  }, [])

  const [isOpen, setIsOpen] = React.useState(false);

  const onToggleClick = () => {
    setIsOpen(!isOpen);
  };

  const [locationSelections, setLocationSelections] = React.useState<string[]>([]);
  const [filterOptions, setFilterOptions] = React.useState<string[]>(['Running', 'Pending']);
  const [statusSelections, setStatusSelections] = React.useState<string[]>(['Running', 'Pending']);
  const [hostsSelections, setHostsSelections] = React.useState<string[]>([]);


  const areSelectionsPresent = locationSelections.length > 0 || statusSelections.length > 0 || hostsSelections.length > 0;
  const toggleRef = React.useRef<HTMLButtonElement>(null);
  const toggle = (
    <></>
  );

  const _isSelected = (_type) => {
    return statusSelections.includes(_type)
  }

  const menuRef = React.useRef<HTMLDivElement>(null);
  const menu = (
    <></>
  );

  const isRepoSelectable = (repo: DaemonSet) => repo.metadata.name !== 'a';
  const selectableRepos = daemonSets.filter(isRepoSelectable);
  const [selectedRepoNames, setSelectedRepoNames] = React.useState<string[]>([]);
  const setRepoSelected = (repo: DaemonSet, isSelecting = true) =>
    setSelectedRepoNames((prevSelected) => {
      const otherSelectedRepoNames = prevSelected.filter((r) => r !== repo.metadata.name);
      return isSelecting && isRepoSelectable(repo) ? [...otherSelectedRepoNames, repo.metadata.name] : otherSelectedRepoNames;
    });
  const selectAllRepos = (isSelecting = true) =>
    setSelectedRepoNames(isSelecting ? selectableRepos.map((r) => r.metadata.name) : []);
  const areAllReposSelected = selectedRepoNames.length === selectableRepos.length;

  const containerRef = React.useRef<HTMLDivElement>(null);
  const select = (
    <div ref={containerRef}>
      <Popper
        trigger={toggle}
        triggerRef={toggleRef}
        popper={menu}
        popperRef={menuRef}
        appendTo={containerRef.current || undefined}
        isVisible={isOpen}
      />
    </div>
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (isOpen && !menuRef.current?.contains(event.target as Node) && !toggleRef.current?.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen, menuRef]);

  const onChipDelete = (category: string, chip: string) => {
    if (category === 'status') {
      setStatusSelections(statusSelections.filter((selection) => selection !== chip));
    } else {
      setLocationSelections(locationSelections.filter((selection) => selection !== chip));
    }
  };

  const toolbar = (
    <Toolbar
      id="filter-faceted-toolbar"
      clearAllFilters={() => {
      }}
    >
      <ToolbarContent>
        <ToolbarToggleGroup toggleIcon={<FilterIcon />} breakpoint="xl">
          {/* <ToolbarFilter
            chips={statusSelections}
            deleteChip={(category, chip) => onChipDelete(category as string, chip as string)}
            deleteChipGroup={() => setStatusSelections([])}
            categoryName="Status"
            showToolbarItem={false}
          >
            <div />
          </ToolbarFilter> */}
          {/* <ToolbarFilter
            chips={locationSelections}
            deleteChip={(category, chip) => onChipDelete(category as string, chip as string)}
            deleteChipGroup={() => setLocationSelections([])}
            categoryName="Labels"
          > */}
          {select}
          {/* </ToolbarFilter> */}
        </ToolbarToggleGroup>
      </ToolbarContent>
    </Toolbar>
  );

  const [value, setValue] = React.useState('');

  const initialExpandedRepoNames = []; // Default to all expanded
  const [expandedRepoNames, setExpandedRepoNames] = React.useState<string[]>(initialExpandedRepoNames);
  const setRepoExpanded = (service: DaemonSet, isExpanding = true) =>
    setExpandedRepoNames((prevExpanded) => {
      const otherExpandedRepoNames = prevExpanded.filter((r) => r !== service.metadata.name);
      return isExpanding ? [...otherExpandedRepoNames, service.metadata.name] : otherExpandedRepoNames;
    });
  const isServiceExpanded = (repo: DaemonSet) => expandedRepoNames.includes(repo.metadata.name);



  // selection
  const isRepoSelected = (repo: DaemonSet) => selectedRepoNames.includes(repo.metadata.name);

  const [shifting, setShifting] = React.useState(false);
  const [recentSelectedRowIndex, setRecentSelectedRowIndex] = React.useState<number | null>(null);

  const onSelectRepo = (repo: DaemonSet, rowIndex: number, isSelecting: boolean) => {
    // If the user is shift + selecting the checkboxes, then all intermediate checkboxes should be selected
    if (shifting && recentSelectedRowIndex !== null) {
      const numberSelected = rowIndex - recentSelectedRowIndex;
      const intermediateIndexes =
        numberSelected > 0
          ? Array.from(new Array(numberSelected + 1), (_x, i) => i + recentSelectedRowIndex)
          : Array.from(new Array(Math.abs(numberSelected) + 1), (_x, i) => i + rowIndex);
      intermediateIndexes.forEach((index) => setRepoSelected(daemonSets[index], isSelecting));
    } else {
      setRepoSelected(repo, isSelecting);
    }
    setRecentSelectedRowIndex(rowIndex);
  };

  const onDeleteClick = () => {
    console.log('selected', selectedRepoNames);
  }


  return (
    <React.Fragment>
      <div className='controls-pane'>

        <div className='top-padding-controls-toolbar'>

          <TextInput
            className='server-name-filter deployment-filter-margin'
            placeholder="Filter by daemon set name"
            value={value}
            type="text"
            onChange={
              (_event, value) => {
                setValue(value);
                const filtered = daemonSets.filter((item: DaemonSet) => {
                  console.log('includes:', item.metadata.name.includes(value));
                  return item.metadata.name.includes(value);
                })
                setFilteredDaemonSets(filtered);
              }
            }
            aria-label="text input example"
          />

        </div>
        <div className='top-padding-controls-toolbar'>
          <Button onClick={onDeleteClick} variant="plain" aria-label="Action">
            <TrashIcon />
          </Button>

        </div>
      </div>

      <Table
        variant="compact"
        aria-label="Simple table">
        <Thead>
          <Tr>
            <Th
              select={{
                onSelect: (_event, isSelecting) => selectAllRepos(isSelecting),
                isSelected: areAllReposSelected
              }}
              aria-label="Row select"
            />
            <Th />
            <Th>Name</Th>
            <Th></Th>
            <Th>Status</Th>
            <Th>Image</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredDaemonSets.map((item: DaemonSet, rowIndex) => {
            return <>
              <Tr key={item.metadata.name}>
                <Td
                  select={{
                    rowIndex,
                    onSelect: (_event, isSelecting) => onSelectRepo(item, rowIndex, isSelecting),
                    isSelected: isRepoSelected(item),
                    isDisabled: !isRepoSelectable(item)
                  }}
                />
                <Td
                  expand={
                    item.metadata.name
                      ? {
                        rowIndex,
                        isExpanded: isServiceExpanded(item),
                        onToggle: () => setRepoExpanded(item, !isServiceExpanded(item)),
                        expandId: 'composable-expandable-example'
                      }
                      : undefined
                  }
                />
                <Td>{item.metadata.name}</Td>
                <Td>
                  <div className='status-container'><GreenCircle></GreenCircle> Active</div>
                </Td>
                <Td>{item.status.numberReady} / {item.status.desiredNumberScheduled} Ready</Td>
                <Td>{item.spec.template.spec.containers[0].image}</Td>
              </Tr>
              {item.metadata.name ? (
                <>
                  <Tr isExpanded={isServiceExpanded(item)}>
                    <Td dataLabel="Repo detail 0">
                      <ExpandableRowContent>

                      </ExpandableRowContent>
                    </Td>
                    <Td dataLabel="Repo detail 1">
                      <ExpandableRowContent>

                      </ExpandableRowContent>
                    </Td>
                    <Td dataLabel="Repo detail 2">
                      <ExpandableRowContent>
                        <div className='bold-text metadata-title'>Labels</div>
                        {Object.entries(item.metadata.labels).map(([key, value]) => {
                          // Pretty straightforward - use key for the key and value for the value.
                          // Just to clarify: unlike object destructuring, the parameter names don't matter here.
                          return <div className='chip-outer-container'><span className='chip-system'>{key}: {value}</span></div>
                        })}

                      </ExpandableRowContent>
                    </Td>
                    <Td dataLabel="Repo detail 3" colSpan={2}>
                      <ExpandableRowContent>
                      </ExpandableRowContent>
                    </Td>

                  </Tr>

                </>
              ) : null}
            </>
          })}
        </Tbody>
      </Table>

    </React.Fragment>
  );
};