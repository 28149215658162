import * as React from 'react';
import { Tab, TabContent, TabTitleIcon, TabTitleText, Tabs, Tooltip, TreeViewDataItem } from '@patternfly/react-core';
import { VMTreeViewImpl } from '@app/TreeViewImpl/VMTreeViewImpl';
import { PodTreeViewImpl } from '@app/TreeViewImpl/PodTreeViewImpl';
import { NodeTreeViewImpl } from '@app/TreeViewImpl/NodeTreeViewImpl';
import { VolumeTreeViewImpl } from '@app/TreeViewImpl/VolumeTreeViewImpl';

import VirtualMachineIcon from '@patternfly/react-icons/dist/esm/icons/virtual-machine-icon';
import OutlinedWindowRestoreIcon from '@patternfly/react-icons/dist/esm/icons/outlined-window-restore-icon';
import DatabaseIcon from '@patternfly/react-icons/dist/esm/icons/database-icon';
import ZoneIcon from '@patternfly/react-icons/dist/esm/icons/zone-icon';
import { CubesIcon, OutlinedHddIcon } from '@patternfly/react-icons';
import MicrochipIcon from '@patternfly/react-icons/dist/esm/icons/microchip-icon';


export interface TreeViewProps {
  onSelect: (event: React.MouseEvent, item: TreeViewDataItem) => void;
  /** Callback for expanding a node with children. */
}
export const Sidebar: React.FunctionComponent<TreeViewProps> = ({ onSelect }) => {
  const [selected, setSelected] = React.useState<TreeViewDataItem>();
  const [activeItems, setActiveItems] = React.useState<TreeViewDataItem[]>();



  const [activeTabKey, setActiveTabKey] = React.useState<string | number>(0);
  const [isBox, setIsBox] = React.useState<boolean>(false);
  // Toggle currently active tab
  const handleTabClick = (
    event: React.MouseEvent<any> | React.KeyboardEvent | MouseEvent,
    tabIndex: string | number
  ) => {
    setActiveTabKey(tabIndex);
  };

  const toggleBox = (checked: boolean) => {
    setIsBox(checked);
  };

  const tooltip = (
    <Tooltip content="Aria-disabled tabs are like disabled tabs, but focusable. Allows for tooltip support." />
  );

  const contentRef1Sidebar = React.createRef<HTMLElement>();
  const contentRef2Sidebar = React.createRef<HTMLElement>();
  const contentRef3Sidebar = React.createRef<HTMLElement>();
  const contentRef4Sidebar = React.createRef<HTMLElement>();

  return (
    <React.Fragment>
      <div className="sidebar-tabs">

        <Tabs
          activeKey={activeTabKey}
          onSelect={handleTabClick}
          aria-label="Tabs in the seperate content example"
          role="region"
        >
          <Tab
            eventKey={0}
            title={
              <>
                <TabTitleIcon>
                  <OutlinedHddIcon />
                </TabTitleIcon>{' '}
              </>}
            tabContentId="refTab1Section"
            tabContentRef={contentRef1Sidebar}
          />
          <Tab
            eventKey={1}
            title={
              <>
                <TabTitleIcon>
                  <MicrochipIcon />
                </TabTitleIcon>{' '}
              </>}
            tabContentId="refTab2Section"
            tabContentRef={contentRef2Sidebar}
          />
          <Tab
            eventKey={2}
            title={
              <>
                <TabTitleIcon>
                  <CubesIcon />
                </TabTitleIcon>{' '}
              </>}
            tabContentId="refTab3Section"
            tabContentRef={contentRef3Sidebar}
          />
          <Tab
            eventKey={3}
            title={
              <>
                <TabTitleIcon>
                  <ZoneIcon />
                </TabTitleIcon>{' '}
              </>}
            tabContentId="refTab4Section"
            tabContentRef={contentRef4Sidebar}
          />
        </Tabs>
        <div className="sidebar-panel-tabs-content">
          <div className="inner-sidebar-panel-tabs-content">
            <TabContent
              eventKey={0}
              id="refTab1SectionSidebar"
              ref={contentRef1Sidebar}
              aria-label="This is content for the first separate content tab"
            >
              <VMTreeViewImpl onSelect={onSelect} />
            </TabContent>

            <TabContent
              eventKey={1}
              id="refTab2SectionSidebar"
              ref={contentRef2Sidebar}
              aria-label="This is content for the third separate content tab"
              hidden
            >
              <NodeTreeViewImpl onSelect={onSelect} />
            </TabContent>

            <TabContent
              eventKey={2}
              id="refTab3SectionSidebar"
              ref={contentRef3Sidebar}
              aria-label="This is content for the second separate content tab"
              hidden
            >
              <PodTreeViewImpl onSelect={onSelect} />
            </TabContent>


            <TabContent
              eventKey={3}
              id="refTab4SectionSidebar"
              ref={contentRef4Sidebar}
              aria-label="This is content for the third separate content tab"
              hidden
            >
              <VolumeTreeViewImpl onSelect={onSelect} />
            </TabContent>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};