import { Badge, Button, Checkbox, Chip, Divider, Dropdown, DropdownItem, DropdownList, Icon, Label, Menu, MenuContent, MenuGroup, MenuItem, MenuList, MenuToggle, MenuToggleCheckbox, MenuToggleElement, Pagination, Popper, Spinner, TextInput, Toolbar, ToolbarContent, ToolbarFilter, ToolbarItem, ToolbarToggleGroup } from '@patternfly/react-core';
import { AngleLeftIcon, AngleRightIcon, CaretDownIcon, CatalogIcon, CloudTenantIcon, CodeIcon, CopyIcon, DesktopIcon, FilterIcon, FolderIcon, HistoryIcon, KeyIcon, PortIcon, RegionsIcon, ServicesIcon, StarIcon, StopIcon, TagIcon, TrashIcon, VirtualMachineIcon } from '@patternfly/react-icons';
import { ExpandableRowContent, Table, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import * as React from 'react';
import { GreenCircle } from './indicators/GreenCircle';
import { RedCircle } from './indicators/RedCircle';
import { YellowCircle } from './indicators/YellowCircle';
import { GreyCircle } from './indicators/GreyCircle';
import { ActionListKebab } from './VirtualMachineInstance/Tabs/Summary/ActionListKebab';
import { ChangeOS } from './ChangeOS';
import { Pod, parseVM } from './VMParser';
import { backendURL } from './BackendURL';
import { useContext } from 'react';
import { DataContext } from '@app/DataContext';



const VirtualMachinesView: React.FunctionComponent = () => {
  const { refreshTree, setRefreshTree } = useContext(DataContext);

  const [vms, setVMs] = React.useState<Pod[]>([]);
  const [filteredVMs, setFilteredVMs] = React.useState<Pod[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [hosts, setHosts] = React.useState<string[]>([]);
  const [phases, setPhases] = React.useState<string[]>([]);
  const [labels, setLabels] = React.useState<string[]>([]);

  React.useEffect(() => {
    fetch(`${backendURL}/api/virtual-machines/demo/vms`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const pods: Pod[] = [];
        const hostsSet = new Set<string>();
        const phasesSet = new Set<string>();
        const labelsSet = new Set<string>();

        data.forEach(function (item) {
          const skipMetrics = true;
          const pod = parseVM(item, skipMetrics);

          if (pod.nodeName !== 'Not Specified') {
            hostsSet.add(pod.nodeName)
          }

          phasesSet.add(pod.printableStatus);

          pod.labelList.forEach((item) => {
            const label = `${item.key}: ${item.value}`;
            labelsSet.add(label);
          })

          pods.push(pod);

          setRefreshTree(refreshTree + 1);
        })

        const hostsArray = Array.from(hostsSet.values());
        setHosts(hostsArray)
        console.log(hostsArray);

        const phasesArray = Array.from(phasesSet.values());
        setPhases(phasesArray);
        console.log(phasesArray);

        const labelsArray = Array.from(labelsSet.values());
        setLabels(labelsArray);
        console.log(labelsArray);

        console.log(pods);
        setVMs(pods);
        setIsLoading(false);
        setFilteredVMs(pods);
      })

    return () => { };
  }, []);


  const columnNames = {
    name: 'Name',
    vmState: 'VM State',
    hardware: 'Hardware',
    privateIp: 'Private IP',
    elasticIP: 'Elastic IP',
    node: 'Node',
    launchTime: 'Launch Time',
  };

  // In this example, expanded rows are tracked by the repo names from each row. This could be any unique identifier.
  // This is to prevent state from being based on row order index in case we later add sorting.
  // Note that this behavior is very similar to selection state.
  const initialExpandedRepoNames = []; // Default to all expanded
  const [expandedRepoNames, setExpandedRepoNames] = React.useState<string[]>(initialExpandedRepoNames);
  const setRepoExpanded = (repo: Pod, isExpanding = true) =>
    setExpandedRepoNames((prevExpanded) => {
      const otherExpandedRepoNames = prevExpanded.filter((r) => r !== repo.name);
      return isExpanding ? [...otherExpandedRepoNames, repo.name] : otherExpandedRepoNames;
    });
  const isRepoExpanded = (repo: Pod) => expandedRepoNames.includes(repo.name);
  const isRepoChangeOS = (repo: Pod) => true;

  const [isExampleCompact, setIsExampleCompact] = React.useState(true);

  const isRepoSelectable = (repo: Pod) => repo.name !== 'a'; // Arbitrary logic for this example
  const selectableRepos = vms.filter(isRepoSelectable);

  const [selectedRepoNames, setSelectedRepoNames] = React.useState<string[]>([]);

  const selectAllRepos = (isSelecting = true) =>
    setSelectedRepoNames(isSelecting ? selectableRepos.map((r) => r.name) : []);
  const areAllReposSelected = selectedRepoNames.length === selectableRepos.length;

  const setRepoSelected = (repo: Pod, isSelecting = true) =>
    setSelectedRepoNames((prevSelected) => {
      const otherSelectedRepoNames = prevSelected.filter((r) => r !== repo.name);
      return isSelecting && isRepoSelectable(repo) ? [...otherSelectedRepoNames, repo.name] : otherSelectedRepoNames;
    });

  const [recentSelectedRowIndex, setRecentSelectedRowIndex] = React.useState<number | null>(null);

  const onSelectRepo = (repo: Pod, rowIndex: number, isSelecting: boolean) => {
    // If the user is shift + selecting the checkboxes, then all intermediate checkboxes should be selected

    setRepoSelected(repo, isSelecting);
    setRecentSelectedRowIndex(rowIndex);
  };

  const isRepoSelected = (repo: Pod) => selectedRepoNames.includes(repo.name);

  //dropdown
  const [isOpen, setIsOpen] = React.useState(false);

  const onToggleClick = () => {
    setIsOpen(!isOpen);
  };

  const filterTable = (vms, states, labels, hosts, value) => {
    let toFilter = [...vms];

    const selectedStates = [...states];
    if (selectedStates.length > 0) {
      toFilter = toFilter.filter((item) => {
        return selectedStates.includes(item.printableStatus)
      })
    }

    const selectedHosts = [...hosts];
    if (selectedHosts.length > 0) {
      toFilter = toFilter.filter((item) => {
        return selectedHosts.includes(item.nodeName)
      })
    }


    const selectedLabels = [...labels];
    if (selectedLabels.length > 0) {
      toFilter = toFilter.filter((item) => {
        const transformedLabels = item.labelList.map((item) => { return item.key + ': ' + item.value })
        const containsAllLabels = selectedLabels.every((item) => {
          console.log('containsAllLabels', item);
          return transformedLabels.includes(item)
        })
        return containsAllLabels;
      })
    }

    if (value !== '') {
      toFilter = toFilter.filter(item => {
        return item.name.indexOf(value as string) !== -1
      });
    }

    setFilteredVMs(toFilter);
  }

  const onSelect = (_event: React.MouseEvent<Element, MouseEvent> | undefined, selectedValue: string | number | undefined) => {
    // eslint-disable-next-line no-console
    console.log('selected', selectedValue);
    const _value: string = selectedValue as string;
    // if (_value === 'Needs Maintenance') {
    //   _value = 'Pending';
    // }

    const ss = [...statusSelections];
    if (phases.includes(_value)) {
      if (ss.includes(_value)) {
        const index = ss.indexOf(_value, 0);
        if (index > -1) {
          ss.splice(index, 1);
        }
      } else {
        ss.push(_value);
      }
      setStatusSelections(ss)
    }

    const ls = [...locationSelections];
    if (labels.includes(_value)) {
      if (ls.includes(_value)) {
        const index = ls.indexOf(_value, 0);
        if (index > -1) {
          ls.splice(index, 1);
        }
      } else {
        ls.push(_value);
      }
      setLocationSelections(ls)
    }

    const hs = [...hostsSelections];
    if (hosts.includes(_value)) {
      if (hs.includes(_value)) {
        const index = hs.indexOf(_value, 0);
        if (index > -1) {
          hs.splice(index, 1);
        }
      } else {
        hs.push(_value);
      }
      setHostsSelections(hs)
    }


    filterTable(vms, ss, ls, hs, value);

  };

  // text input
  const [value, setValue] = React.useState('');

  //filters
  const bulkSelectMenuRef = React.useRef<HTMLDivElement>(null);
  const bulkSelectToggleRef = React.useRef<any>(null);

  const [isBulkSelectOpen, setIsBulkSelectOpen] = React.useState<boolean>(false);

  const bulkSelectMenu = (
    <Menu
      id="filter-faceted-input-bulk-select"
      ref={bulkSelectMenuRef}
      onSelect={(_ev, itemId) => {
        selectAllRepos(itemId === 1 || itemId === 2);
        setIsBulkSelectOpen(!isBulkSelectOpen);
        bulkSelectToggleRef.current?.querySelector('button').focus();
      }}
    >
      <MenuContent>
        <MenuList>
          <MenuItem itemId={0}>Select none (0 items)</MenuItem>
          <MenuItem itemId={1}>Select page ({filteredVMs.length} items)</MenuItem>
          <MenuItem itemId={2}>Select all ({filteredVMs.length} items)</MenuItem>
        </MenuList>
      </MenuContent>
    </Menu>
  );


  const onBulkSelectToggleClick = (ev: React.MouseEvent) => {
    ev.stopPropagation(); // Stop handleClickOutside from handling
    setTimeout(() => {
      if (bulkSelectMenuRef.current) {
        const firstElement = bulkSelectMenuRef.current.querySelector('li > button:not(:disabled)');
        firstElement && (firstElement as HTMLElement).focus();
      }
    }, 0);
    setIsBulkSelectOpen(!isBulkSelectOpen);
  };


  let menuToggleCheckmark: boolean | null = false;
  const areSomeReposSelected = selectedRepoNames.length > 0;
  if (areAllReposSelected) {
    menuToggleCheckmark = true;
  } else if (areSomeReposSelected) {
    menuToggleCheckmark = null;
  }

  const bulkSelectToggle = (
    <MenuToggle
      ref={bulkSelectToggleRef}
      onClick={onBulkSelectToggleClick}
      isExpanded={isBulkSelectOpen}
      splitButtonOptions={{
        items: [
          <MenuToggleCheckbox
            id="filter-faceted-input-bulk-select"
            key="filter-faceted-input-bulk-select"
            aria-label="Select all"
            isChecked={menuToggleCheckmark}
            onChange={(checked, _event) => selectAllRepos(checked)}
          />
        ]
      }}
      aria-label="Full table selection checkbox"
    />
  );


  const bulkSelectContainerRef = React.useRef<HTMLDivElement>(null);
  const toolbarBulkSelect = (
    <div ref={bulkSelectContainerRef}>
      <Popper
        trigger={bulkSelectToggle}
        triggerRef={bulkSelectToggleRef}
        popper={bulkSelectMenu}
        popperRef={bulkSelectMenuRef}
        appendTo={bulkSelectContainerRef.current || undefined}
        isVisible={isBulkSelectOpen}
      />
    </div>
  );

  //TODO proper naming
  const [locationSelections, setLocationSelections] = React.useState<string[]>([]);
  const [statusSelections, setStatusSelections] = React.useState<string[]>([]);
  const [hostsSelections, setHostsSelections] = React.useState<string[]>([]);

  const areSelectionsPresent = locationSelections.length > 0 || statusSelections.length > 0 || hostsSelections.length > 0;
  const toggleRef = React.useRef<HTMLButtonElement>(null);
  const toggle = (
    <MenuToggle
      ref={toggleRef}
      onClick={onToggleClick}
      isExpanded={isOpen}
      {...(areSelectionsPresent && {
        badge: <Badge isRead>{locationSelections.length + statusSelections.length + hostsSelections.length}</Badge>
      })}
      icon={<FilterIcon />}
      style={
        {
          width: '200px'
        } as React.CSSProperties
      }
    >
      Filter
    </MenuToggle>
  );

  const _isSelected = (phase) => {
    console.log('1234');
    return statusSelections.includes(phase)
  }

  const getPhase = (phase) => {
    // if (phase === 'Pending') return 'Needs Maintenance';
    return phase;
  }

  const menuRef = React.useRef<HTMLDivElement>(null);
  const menu = (
    <Menu ref={menuRef} id="filter-faceted-location-menu" onSelect={onSelect} selected={locationSelections}>
      <MenuContent>
        <MenuList>
          <MenuGroup label="Status">
            {phases.map((phase, rowIndex) => {

              return (
                <MenuItem hasCheckbox isSelected={_isSelected(phase)} itemId={phase}>
                  {getPhase(phase)}
                </MenuItem>
              )
            }
            )}
          </MenuGroup>
          <MenuGroup label="Host">
            {hosts.map((host, rowIndex) => {

              return (
                <MenuItem hasCheckbox isSelected={hostsSelections.includes(host)} itemId={host}>
                  {host}
                </MenuItem>
              )
            }
            )}
          </MenuGroup>
          <MenuGroup label="Label">
            {labels.map((label, rowIndex) => {

              return (
                <MenuItem hasCheckbox isSelected={locationSelections.includes(label)} itemId={label}>
                  {label}
                </MenuItem>
              )
            }
            )}
          </MenuGroup>
        </MenuList>
      </MenuContent>
    </Menu>
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (isOpen && !menuRef.current?.contains(event.target as Node) && !toggleRef.current?.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen, menuRef]);

  const containerRef = React.useRef<HTMLDivElement>(null);
  const select = (
    <div ref={containerRef}>
      <Popper
        trigger={toggle}
        triggerRef={toggleRef}
        popper={menu}
        popperRef={menuRef}
        appendTo={containerRef.current || undefined}
        isVisible={isOpen}
      />
    </div>
  );


  const toolbarPagination = (
    <Pagination
      titles={{ paginationAriaLabel: 'Faceted filter group pagination' }}
      itemCount={filteredVMs.length}
      perPage={10}
      page={1}
      widgetId="filter-faceted-mock-pagination"
      isCompact
    />
  );

  const onChipDelete = (category: string, chip: string) => {
    if (category === 'status') {
      setStatusSelections(statusSelections.filter((selection) => selection !== chip));
    } else {
      setLocationSelections(locationSelections.filter((selection) => selection !== chip));
    }
  };

  const toolbar = (
    <Toolbar
      id="filter-faceted-toolbar"
      clearAllFilters={() => {
        setStatusSelections([]);
        setLocationSelections([]);
        setHostsSelections([]);
      }}
    >
      <ToolbarContent>
        <ToolbarToggleGroup toggleIcon={<FilterIcon />} breakpoint="xl">
          {/* <ToolbarFilter
            chips={statusSelections}
            deleteChip={(category, chip) => onChipDelete(category as string, chip as string)}
            deleteChipGroup={() => setStatusSelections([])}
            categoryName="Status"
            showToolbarItem={false}
          >
            <div />
          </ToolbarFilter> */}
          {/* <ToolbarFilter
            chips={locationSelections}
            deleteChip={(category, chip) => onChipDelete(category as string, chip as string)}
            deleteChipGroup={() => setLocationSelections([])}
            categoryName="Labels"
          > */}
          {select}
          {/* </ToolbarFilter> */}
        </ToolbarToggleGroup>
      </ToolbarContent>
    </Toolbar>
  );

  return (
    <React.Fragment>
      {!isLoading &&

        <>
          <div className='controls-pane'>
            {toolbar}

            <div className='top-padding-controls-toolbar'>

              <TextInput
                className='server-name-filter'
                placeholder="Filter by server name"
                value={value}
                type="text"
                onChange={
                  (_event, value) => {
                    setValue(value);
                    //filter out by name
                    filterTable(vms, statusSelections, locationSelections, hostsSelections, value);
                  }
                }
                aria-label="text input example"
              />

            </div>

            <div className='top-padding-controls-toolbar'>
              <Button variant="plain" aria-label="Action">
                <StopIcon />
              </Button>

              <Button variant="plain" aria-label="Action">
                <HistoryIcon />
              </Button>

              <Button variant="plain" aria-label="Action">
                <DesktopIcon />
              </Button>

              <Button variant="plain" aria-label="Action">
                <CopyIcon />
              </Button>

              <Button variant="plain" aria-label="Action">
                <TrashIcon />
              </Button>

            </div>
          </div>
          <Table aria-label="Expandable table" variant={isExampleCompact ? 'compact' : undefined}>
            <Thead>
              <Tr>
                <Th
                  select={{
                    onSelect: (_event, isSelecting) => selectAllRepos(isSelecting),
                    isSelected: areAllReposSelected
                  }}
                  aria-label="Row select"
                />
                <Th />
                <Th className='bold-text' width={30}>{columnNames.name}</Th>
                <Th className='bold-text' width={10}>{columnNames.vmState}</Th>
                <Th className='bold-text' width={20}>{columnNames.hardware}</Th>
                <Th className='bold-text' width={10}>{columnNames.privateIp}</Th>
                <Th className='bold-text' width={10}>{columnNames.elasticIP}</Th>
                <Th className='bold-text' width={10}>{columnNames.node}</Th>
                <Th className='bold-text' width={10}>{columnNames.launchTime}</Th>
              </Tr>
            </Thead>
            {filteredVMs.map((vm, rowIndex) => {
              // Some arbitrary examples of how you could customize the child row based on your needs

              return (
                <Tbody key={vm.name} isExpanded={isRepoExpanded(vm)}>
                  <Tr>
                    <Td
                      select={{
                        rowIndex,
                        onSelect: (_event, isSelecting) => {
                          onSelectRepo(vm, rowIndex, isSelecting)

                        },
                        isSelected: isRepoSelected(vm),
                        isDisabled: !isRepoSelectable(vm)
                      }}
                    />
                    <Td
                      expand={
                        vm.name
                          ? {
                            rowIndex,
                            isExpanded: isRepoExpanded(vm),
                            onToggle: () => setRepoExpanded(vm, !isRepoExpanded(vm)),
                            expandId: 'composable-expandable-example'
                          }
                          : undefined
                      }
                    />
                    <Td dataLabel={columnNames.name}>{vm.name}</Td>
                    <Td dataLabel={columnNames.vmState}>
                      {vm.printableStatus === 'Running' && <div className='status-container'><GreenCircle></GreenCircle> Running</div>}
                      {vm.printableStatus === 'Paused' && <div className='status-container'><GreyCircle></GreyCircle> Paused</div>}
                      {vm.printableStatus === 'Stopped' && <div className='status-container'><RedCircle></RedCircle> Stopped</div>}
                      {vm.printableStatus === 'Provisioning' && <div className='status-container'><YellowCircle></YellowCircle> Provisioning</div>}
                    </Td>
                    <Td dataLabel={columnNames.hardware}>vCPU-{vm.cores} vRAM-{vm.memory}</Td>
                    <Td dataLabel={columnNames.privateIp}>{vm.ip}</Td>
                    <Td dataLabel={columnNames.elasticIP}></Td>
                    <Td dataLabel={columnNames.node}>{vm.nodeName}</Td>
                    <Td dataLabel={columnNames.launchTime}></Td>
                  </Tr>
                  {vm.name ? (
                    <>
                      <Tr isExpanded={isRepoExpanded(vm)}>
                        <Td dataLabel="Repo detail 1">
                          <ExpandableRowContent></ExpandableRowContent>
                        </Td>
                        <Td dataLabel="Repo detail 2">
                          <ExpandableRowContent></ExpandableRowContent>
                        </Td>
                        <Td dataLabel="Repo detail 3" colSpan={2}>
                          <ExpandableRowContent>
                            <div>
                              <div className='bold-text metadata-title'>DNS Name</div>
                              <div className='metadata-value'>{vm.domain}</div>
                            </div>
                          </ExpandableRowContent>
                        </Td>
                        <Td dataLabel="Repo detail 4">
                          <ExpandableRowContent>
                            <div>
                              <div className='bold-text metadata-title'>SSH user/key</div>
                              <div className='metadata-value'>{vm.sshUser}/{vm.sshKey}</div>
                            </div>
                          </ExpandableRowContent>
                        </Td>
                        <Td dataLabel="Repo detail 5" colSpan={3}>
                          <ExpandableRowContent>
                            <div>
                              <div className='bold-text metadata-title'>Labels</div>
                              <div className='metadata-value'>
                                <div className='tagline'>
                                  {vm.labelList.map(label => (
                                    <div className='label-chip' onClick={(item) => { console.log(item, label) }}>
                                      {label.key}: {label.value}
                                    </div>
                                  ))}

                                  {vm.labelList.length === 0 && <div>Not Specified</div>}

                                </div>
                                {/* <div>
                                  <Label color="purple" icon={<PortIcon />}>
                                    err
                                  </Label> {' '}
                                  <Label color="red" icon={<CatalogIcon />}>
                                    tools
                                  </Label> {' '}
                                  <Label color="cyan" icon={<StarIcon />}>
                                    pencil
                                  </Label>
                                </div> */}
                              </div>
                            </div>

                          </ExpandableRowContent>
                        </Td>
                      </Tr>

                    </>
                  ) : null}
                </Tbody>
              );
            })}
          </Table>
        </>

      }

      {isLoading &&

        <Spinner></Spinner>

      }
    </React.Fragment>
  )
}

export { VirtualMachinesView };


