import { backendURL } from '@app/TreeContainer/BackendURL';
import * as React from 'react';
declare const moment: any;
import { ExpandableRowContent, Table, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import { Badge, Button, Menu, MenuContent, MenuGroup, MenuItem, MenuList, MenuToggle, Popper, TextInput, Toolbar, ToolbarContent, ToolbarToggleGroup } from '@patternfly/react-core';
import { FilterIcon, TrashIcon } from '@patternfly/react-icons';
import { GreenCircle } from '@app/TreeContainer/indicators/GreenCircle';

export interface Label {
  value: string,
}

export interface Port {
  targetPort: string,
  nodePort: string,
  port: string,
  protocol: string,
  name: string

}

export interface Ingress {
  value: string
}


export interface Service {
  name: string;
  labels: Label[];
  ports: Port[];
  ingresses: Ingress[];
  creationTimestamp: string;
}

export const SystemServiceView: React.FunctionComponent = () => {


  const defaultState = { items: [] };
  const servicesType: Service[] = [];
  const [services, setServices] = React.useState(defaultState);
  const [defaultServices, setDefaultServices] = React.useState(servicesType);
  const [filteredServices, setFilteredServices] = React.useState(servicesType);

  const getServices = () => {
    const url = `${backendURL}/api/system/demo/services`;
    const response = fetch(url)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log('services', data);
        setServices(data);
        const servicesList: Service[] = [];
        data.items.forEach((item) => {
          const service: Service = {
            name: item.metadata.name,
            labels: [],
            ports: [],
            ingresses: [],
            creationTimestamp: item.metadata.creationTimestamp
          };
          const labelList: Label[] = [];
          for (const key in item.metadata.labels) {
            // Get the strongly typed value with this name:
            const _value = item.metadata.labels[key];
            const labelItem: Label = {
              value: `${key}: ${_value}`
            };
            labelList.push(labelItem);
          }
          service.labels = labelList;
          servicesList.push(service);

          const portList: Port[] = [];
          item.spec.ports.forEach((_port) => {
            const port: Port = {
              targetPort: _port.targetPort,
              nodePort: _port.nodePort,
              port: _port.port,
              protocol: _port.protocol,
              name: _port.name
            };
            portList.push(port);
          })
          service.ports = portList;

          const ingressList: Ingress[] = [];
          const isThereIngress = !!item.status.loadBalancer.ingress;
          if (isThereIngress) {
            item.status.loadBalancer.ingress.forEach((_ingress) => {
              const ingress: Ingress = {
                value: _ingress.ip
              };
              ingressList.push(ingress);
            })
            service.ingresses = ingressList;
          } else {
            service.ingresses = [];
          }

        })
        console.log(servicesList);
        setFilteredServices(servicesList);
        setDefaultServices(servicesList);
      })
  }

  React.useEffect(() => {
    getServices();
  }, [])

  const columnNames = {
    name: 'Service',
    status: 'Status',
    labels: 'Labels',
    ports: 'Ports',
    ingress: 'Ingress',
    creationTimestamp: 'Creation Timestamp'
  };

  const [isOpen, setIsOpen] = React.useState(false);

  const onToggleClick = () => {
    setIsOpen(!isOpen);
  };

  const [locationSelections, setLocationSelections] = React.useState<string[]>([]);
  const [filterOptions, setFilterOptions] = React.useState<string[]>(['web', 'websecure', 'http', 'ttyd']);
  const [statusSelections, setStatusSelections] = React.useState<string[]>(['web', 'websecure', 'http', 'ttyd']);
  const [hostsSelections, setHostsSelections] = React.useState<string[]>([]);


  const areSelectionsPresent = locationSelections.length > 0 || statusSelections.length > 0 || hostsSelections.length > 0;
  const toggleRef = React.useRef<HTMLButtonElement>(null);
  const toggle = (
    <MenuToggle
      ref={toggleRef}
      onClick={onToggleClick}
      isExpanded={isOpen}
      {...(areSelectionsPresent && {
        badge: <Badge isRead>{locationSelections.length + statusSelections.length + hostsSelections.length}</Badge>
      })}
      icon={<FilterIcon />}
      style={
        {
          width: '200px'
        } as React.CSSProperties
      }
    >
      Filter
    </MenuToggle>
  );

  const onSelect = (_event: React.MouseEvent<Element, MouseEvent> | undefined, selectedValue: string | number | undefined) => {
    console.log('selected', selectedValue);
    const _value: string = selectedValue as string;

    const ss = [...statusSelections];
    if (ss.includes(_value)) {
      const index = ss.indexOf(_value, 0);
      if (index > -1) {
        ss.splice(index, 1);
      }
    } else {
      ss.push(_value);
    }
    setStatusSelections(ss)

    let filtered = [...defaultServices];
    filtered = filtered.filter((item) => {
      console.log('filtering', item);
      let contains = false;
      item.ports.forEach((port) => {
        console.log('port: ', port);
        if (!port.name) {
          contains = true;
        }
        if (ss.includes(port.name)) {
          contains = true;
        }
      })
      return contains;
    })

    setFilteredServices(filtered);
  };

  const _isSelected = (_type) => {
    return statusSelections.includes(_type)
  }

  const menuRef = React.useRef<HTMLDivElement>(null);
  const menu = (
    <Menu ref={menuRef} id="filter-faceted-location-menu" onSelect={onSelect} selected={locationSelections}>
      <MenuContent>
        <MenuList>
          <MenuGroup label="Type">
            {filterOptions.map((selection, rowIndex) => {

              return (
                <MenuItem hasCheckbox isSelected={_isSelected(selection)} itemId={selection}>
                  {selection}
                </MenuItem>
              )
            }
            )}

          </MenuGroup>
        </MenuList>
      </MenuContent>
    </Menu>
  );

  const isRepoSelectable = (repo: Service) => repo.name !== 'a';
  const selectableRepos = filteredServices.filter(isRepoSelectable);
  const [selectedRepoNames, setSelectedRepoNames] = React.useState<string[]>([]);
  const setRepoSelected = (repo: Service, isSelecting = true) =>
    setSelectedRepoNames((prevSelected) => {
      const otherSelectedRepoNames = prevSelected.filter((r) => r !== repo.name);
      return isSelecting && isRepoSelectable(repo) ? [...otherSelectedRepoNames, repo.name] : otherSelectedRepoNames;
    });
  const selectAllRepos = (isSelecting = true) =>
    setSelectedRepoNames(isSelecting ? selectableRepos.map((r) => r.name) : []);
  const areAllReposSelected = selectedRepoNames.length === selectableRepos.length;

  const containerRef = React.useRef<HTMLDivElement>(null);
  const select = (
    <div ref={containerRef}>
      <Popper
        trigger={toggle}
        triggerRef={toggleRef}
        popper={menu}
        popperRef={menuRef}
        appendTo={containerRef.current || undefined}
        isVisible={isOpen}
      />
    </div>
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (isOpen && !menuRef.current?.contains(event.target as Node) && !toggleRef.current?.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen, menuRef]);

  const onChipDelete = (category: string, chip: string) => {
    if (category === 'status') {
      setStatusSelections(statusSelections.filter((selection) => selection !== chip));
    } else {
      setLocationSelections(locationSelections.filter((selection) => selection !== chip));
    }
  };

  const toolbar = (
    <Toolbar
      id="filter-faceted-toolbar"
      clearAllFilters={() => {
      }}
    >
      <ToolbarContent>
        <ToolbarToggleGroup toggleIcon={<FilterIcon />} breakpoint="xl">
          {/* <ToolbarFilter
            chips={statusSelections}
            deleteChip={(category, chip) => onChipDelete(category as string, chip as string)}
            deleteChipGroup={() => setStatusSelections([])}
            categoryName="Status"
            showToolbarItem={false}
          >
            <div />
          </ToolbarFilter> */}
          {/* <ToolbarFilter
            chips={locationSelections}
            deleteChip={(category, chip) => onChipDelete(category as string, chip as string)}
            deleteChipGroup={() => setLocationSelections([])}
            categoryName="Labels"
          > */}
          {select}
          {/* </ToolbarFilter> */}
        </ToolbarToggleGroup>
      </ToolbarContent>
    </Toolbar>
  );

  const [value, setValue] = React.useState('');

  const initialExpandedRepoNames = []; // Default to all expanded
  const [expandedRepoNames, setExpandedRepoNames] = React.useState<string[]>(initialExpandedRepoNames);
  const setRepoExpanded = (service: Service, isExpanding = true) =>
    setExpandedRepoNames((prevExpanded) => {
      const otherExpandedRepoNames = prevExpanded.filter((r) => r !== service.name);
      return isExpanding ? [...otherExpandedRepoNames, service.name] : otherExpandedRepoNames;
    });
  const isServiceExpanded = (repo: Service) => expandedRepoNames.includes(repo.name);



  // selection
  const isRepoSelected = (repo: Service) => selectedRepoNames.includes(repo.name);

  const [shifting, setShifting] = React.useState(false);
  const [recentSelectedRowIndex, setRecentSelectedRowIndex] = React.useState<number | null>(null);

  const onSelectRepo = (repo: Service, rowIndex: number, isSelecting: boolean) => {
    // If the user is shift + selecting the checkboxes, then all intermediate checkboxes should be selected
    if (shifting && recentSelectedRowIndex !== null) {
      const numberSelected = rowIndex - recentSelectedRowIndex;
      const intermediateIndexes =
        numberSelected > 0
          ? Array.from(new Array(numberSelected + 1), (_x, i) => i + recentSelectedRowIndex)
          : Array.from(new Array(Math.abs(numberSelected) + 1), (_x, i) => i + rowIndex);
      intermediateIndexes.forEach((index) => setRepoSelected(filteredServices[index], isSelecting));
    } else {
      setRepoSelected(repo, isSelecting);
    }
    setRecentSelectedRowIndex(rowIndex);
  };

  const onDeleteClick = () => {
    console.log('selected', selectedRepoNames);
    selectedRepoNames.forEach((item) => {
      const url = `${backendURL}/api/system/demo/service/${item}`;
      fetch(url, {
        method: 'DELETE',
      })
        .then(res => res.text()) // or res.json()
        .then(res => {
          console.log(res)
          getServices();
        })
    })


  }


  return (
    <React.Fragment>
      <div className='controls-pane'>{toolbar}

        <div className='top-padding-controls-toolbar'>

          <TextInput
            className='server-name-filter'
            placeholder="Filter by service name"
            value={value}
            type="text"
            onChange={
              (_event, value) => {
                setValue(value);
                const filteredServicesList = defaultServices.filter((item: Service) => {
                  console.log('includes:', item.name.includes(value));
                  return item.name.includes(value);
                })
                setFilteredServices(filteredServicesList);
              }
            }
            aria-label="text input example"
          />

        </div>
        <div className='top-padding-controls-toolbar'>
          <Button onClick={onDeleteClick} variant="plain" aria-label="Action">
            <TrashIcon />
          </Button>

        </div>
      </div>

      <Table
        variant="compact"
        aria-label="Simple table">
        <Thead>
          <Tr>
            <Th
              select={{
                onSelect: (_event, isSelecting) => selectAllRepos(isSelecting),
                isSelected: areAllReposSelected
              }}
              aria-label="Row select"
            />
            <Th />
            <Th>{columnNames.name}</Th>
            <Th>{columnNames.status}</Th>
            <Th>{columnNames.ingress}</Th>
            <Th>{columnNames.creationTimestamp}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredServices.map((item: Service, rowIndex) => {
            return <>
              <Tr key={item.name}>
                <Td
                  select={{
                    rowIndex,
                    onSelect: (_event, isSelecting) => onSelectRepo(item, rowIndex, isSelecting),
                    isSelected: isRepoSelected(item),
                    isDisabled: !isRepoSelectable(item)
                  }}
                />
                <Td
                  expand={
                    item.name
                      ? {
                        rowIndex,
                        isExpanded: isServiceExpanded(item),
                        onToggle: () => setRepoExpanded(item, !isServiceExpanded(item)),
                        expandId: 'composable-expandable-example'
                      }
                      : undefined
                  }
                />
                <Td>{item.name}</Td>
                <Td><div className='status-container'><GreenCircle></GreenCircle> Running</div></Td>
                <Td>{item.ingresses.map((label, index) => {
                  return <div>{label.value}</div>
                })}</Td>
                <Td>{moment(item.creationTimestamp).fromNow()}</Td>
              </Tr>
              {item.name ? (
                <>
                  <Tr isExpanded={isServiceExpanded(item)}>
                    <Td dataLabel="Repo detail 0">
                      <ExpandableRowContent>

                      </ExpandableRowContent>
                    </Td>
                    <Td dataLabel="Repo detail 1">
                      <ExpandableRowContent>

                      </ExpandableRowContent>
                    </Td>
                    <Td dataLabel="Repo detail 2">
                      <ExpandableRowContent>
                        <div className='bold-text metadata-title'>Labels</div>
                        {item.labels.map((label, index) => {
                          return <div className='chip-outer-container'><span className='chip-system'>{label.value}</span></div>
                        })}
                        {item.labels.length === 0 && 'No Labels'}
                      </ExpandableRowContent>
                    </Td>
                    <Td dataLabel="Repo detail 3" colSpan={2}>
                      <ExpandableRowContent>
                        <div>
                          <div className='bold-text metadata-title'>Ports</div>
                          {item.ports.map((port: Port, index) => {
                            return <>
                              <div className='port-container'>Target Port: {port.targetPort} <br />
                                Node Port: {port.nodePort} <br />
                                Port: {port.port} <br />
                                Protocol: {port.protocol} <br />
                                Name: {port.name}
                              </div>
                            </>
                          })}
                        </div>
                      </ExpandableRowContent>
                    </Td>
                    {/* <Td dataLabel="Repo detail 4">
                      <ExpandableRowContent>
                        <div>
                          <div className='bold-text metadata-title'>SSH user/key</div>
                        </div>
                      </ExpandableRowContent>
                    </Td> */}

                  </Tr>

                </>
              ) : null}
            </>
          })}
        </Tbody>
      </Table>

    </React.Fragment>
  );
};