import * as React from 'react';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, } from '@patternfly/react-core';

import {
  Flex,
  FlexItem,
  Gallery,
  GalleryItem,
  MenuToggle,
  MenuToggleElement,
  Select,
  SelectList,
  SelectOption,
  Title
} from '@patternfly/react-core';
import { ChartArea, ChartGroup, ChartVoronoiContainer } from '@patternfly/react-charts';
import text from '@patternfly/react-styles/css/utilities/Text/text';
import { Pod } from '@app/TreeContainer/VMParser';
import { backendURL } from '@app/TreeContainer/BackendURL';

export interface MonitorTabProps {
  item: Pod | undefined,
}
export const MonitorTab: React.FunctionComponent<MonitorTabProps> =
  ({ item }) => {

    const [metricsData, setMetricsData] = React.useState<{ x: number; y: number; name: string; }[]>([]);
    const [storageData, setStorageData] = React.useState<{ x: number; y: number; name: string; }[]>([]);
    const [cpuData, setCpuData] = React.useState<{ x: number; y: number; name: string; }[]>([]);
    const [filterLabel, setFilterLabel] = React.useState<string>("Last 24 hours");

    const showName = (name) => {
      return name.replace(" ", "").replace("⏵", "").replace("⏹", "").replace("⚠", "").replace("⏸", "");
    }

    React.useEffect(() => {
      console.log('item use effect');

      if (!item || item.name === 'Not Specified') return;

      const url = `${backendURL}/api/metrics/demo/metrics?vm_name=` + showName(item.name) + "&filter=" + encodeURIComponent(filterLabel);

      fetch(url).then((res) => {
        return res.json();
      })
        .then((data) => {

          console.log('handler');

          if (data.ram.data.result.length === 0) {
            return;
          }

          let metrics = data.ram.data.result[0].values;

          let rows: { x: number; y: number; name: string; }[] = [];

          metrics.forEach((element) => {
            const val = Math.round(element[1]) as number;

            const row = {
              x: element[0],
              y: val,
              name: 'RAM usage'
            }

            rows.push(row);
          })

          setMetricsData(rows);
          console.log(metricsData);


          metrics = data.storage.data.result[0].values;

          rows = [];

          metrics.forEach((element) => {
            const val = Math.round(element[1]) as number;

            const row = {
              x: element[0],
              y: val,
              name: 'Storage usage'
            }

            rows.push(row);
          })

          setStorageData(rows);
          console.log(storageData);


          metrics = data.cpu.data.result[0].values;
          rows = [];
          metrics.forEach((element) => {
            const val = Math.round(element[1]) as number;

            const row = {
              x: element[0],
              y: val,
              name: 'CPU usage'
            }

            rows.push(row);
          })

          setCpuData(rows);
          console.log(cpuData);

        })

      return () => { };
    }, [item, filterLabel]);

    const [isOpen, setIsOpen] = React.useState(false);

    const selectItems = (
      <SelectList>
        <SelectOption key="option1" value="Last 30 mins">
          Last 30 mins
        </SelectOption>
        <SelectOption key="option2" value="Last 2 hours">
          Last 2 hours
        </SelectOption>
        <SelectOption key="option3" value="Last 24 hours">
          Last 24 hours
        </SelectOption>
        <SelectOption key="option4" value="Last 3 days">
          Last 3 days
        </SelectOption>
      </SelectList>
    );

    const toggle = (toggleRef: React.Ref<MenuToggleElement>) => (
      <MenuToggle ref={toggleRef} onClick={() => setIsOpen(!isOpen)} isExpanded={isOpen} variant="plainText">
        Filter
      </MenuToggle>
    );

    const headerActions = (
      <Select onSelect={
        (_event: React.MouseEvent<Element, MouseEvent> | undefined, value: string | number | undefined) => {
          console.log('onSelect', _event, value);
          setIsOpen(!isOpen);
          setFilterLabel(value as string);
        }
      } onOpenChange={setIsOpen} isOpen={isOpen} toggle={toggle}>
        {selectItems}
      </Select>
    );



    return (


      <React.Fragment>

        <div className='filter-container'>
          {headerActions}
        </div>

        <div className="main-card-container monitor-container">
          <Gallery hasGutter minWidths={{ default: '360px' }}>
            <GalleryItem>
              <Card id="trend-card-1-card" component="div" className='card-offset'>
                <CardHeader>
                  <Flex direction={{ default: 'column' }} spaceItems={{ default: 'spaceItemsNone' }}>
                    <FlexItem>
                      <CardTitle>
                        <Title headingLevel="h4" size="lg">
                          RAM Utilization (%)
                        </Title>
                      </CardTitle>
                    </FlexItem>
                    <FlexItem>
                      <span className={text.color_200}>{filterLabel}</span>
                    </FlexItem>
                  </Flex>
                </CardHeader>
                <CardBody>
                  <ChartGroup
                    containerComponent={
                      <ChartVoronoiContainer labels={({ datum }) => `${datum.name}: ${datum.y}%`} constrainToVisibleArea />
                    }
                    height={100}
                    maxDomain={{ y: 100 }}
                    padding={0}
                    width={400}
                  >
                    <ChartArea
                      data={metricsData}
                    />
                  </ChartGroup>
                </CardBody>
              </Card>
            </GalleryItem>
          </Gallery>

          <Gallery hasGutter minWidths={{ default: '360px' }}>
            <GalleryItem>
              <Card id="trend-card-2-card" component="div" className='card-offset'>
                <CardHeader>
                  <Flex direction={{ default: 'column' }} spaceItems={{ default: 'spaceItemsNone' }}>
                    <FlexItem>
                      <CardTitle>
                        <Title headingLevel="h4" size="lg">
                          Storage Utilization (%)
                        </Title>
                      </CardTitle>
                    </FlexItem>
                    <FlexItem>
                      <span className={text.color_200}>{filterLabel}</span>
                    </FlexItem>
                  </Flex>
                </CardHeader>
                <CardBody>
                  <ChartGroup
                    containerComponent={
                      <ChartVoronoiContainer labels={({ datum }) => `${datum.name}: ${datum.y}%`} constrainToVisibleArea />
                    }
                    height={100}
                    maxDomain={{ y: 100 }}
                    padding={0}
                    width={400}
                  >
                    <ChartArea
                      data={storageData}
                    />
                  </ChartGroup>
                </CardBody>
              </Card>
            </GalleryItem>
          </Gallery>


          <Gallery hasGutter minWidths={{ default: '360px' }}>
            <GalleryItem>
              <Card id="trend-card-3-card" component="div" className='card-offset'>
                <CardHeader>
                  <Flex direction={{ default: 'column' }} spaceItems={{ default: 'spaceItemsNone' }}>
                    <FlexItem>
                      <CardTitle>
                        <Title headingLevel="h4" size="lg">
                          CPU Utilization (%)
                        </Title>
                      </CardTitle>
                    </FlexItem>
                    <FlexItem>
                      <span className={text.color_200}>{filterLabel}</span>
                    </FlexItem>
                  </Flex>
                </CardHeader>
                <CardBody>
                  <ChartGroup
                    containerComponent={
                      <ChartVoronoiContainer labels={({ datum }) => `${datum.name}: ${datum.y}%`} constrainToVisibleArea />
                    }
                    height={100}
                    maxDomain={{ y: 100 }}
                    padding={0}
                    width={400}
                  >
                    <ChartArea
                      data={cpuData}
                    />
                  </ChartGroup>
                </CardBody>
              </Card>
            </GalleryItem>
          </Gallery>
        </div>

      </React.Fragment>
    );
  };