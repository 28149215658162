import React, {  Dispatch, SetStateAction, useContext } from 'react';
import { FormSelect, Tab, TabTitleText, Tabs } from '@patternfly/react-core';
import { Modal, ModalVariant, Wizard, WizardHeader, WizardStep } from '@patternfly/react-core';
import { CodeEditor, Language } from '@patternfly/react-code-editor';
import {
  FormSelectOption,
  TextInput,
} from '@patternfly/react-core';
import VirtualMachineIcon from '@patternfly/react-icons/dist/esm/icons/virtual-machine-icon';
import { CloudTenantIcon, DatabaseIcon, MemoryIcon, MicrochipIcon, NetworkIcon, VolumeIcon } from '@patternfly/react-icons';
import { ChangeOS } from '../ChangeOS';
import { backendURL } from '../BackendURL';
import { DataContext } from '@app/DataContext';


export interface AddNewVMModalProps {
  isModalOpen: boolean,
  setIsModalOpen: Dispatch<SetStateAction<boolean>>,
}
const AddNewVMModal: React.FunctionComponent = () => {
  const { notifications, setNotifications } = useContext(DataContext);
  const { refreshTree, setRefreshTree } = useContext(DataContext);
  const [ isModalOpen, setIsModalOpen ] = React.useState(false);

  const { showAddModal, setShowAddModal } = useContext(DataContext);
  React.useEffect(() => {
    if (showAddModal !== 0) {
      setIsModalOpen(true);
    }
    console.log('showing delete modal')
  }, [showAddModal]);

  const { viewIndex, setViewIndex } = useContext(DataContext);
  const VM_INDEX = 2;

  const namespace = 'demo';

  const [osImageUrl, setOsImageUrl] = React.useState('');
  const [cloudUser, setCloudUser] = React.useState('');
  const [cloudInit, setCloudInit] = React.useState('');

  const [memory, setMemory] = React.useState('1');
  const [memoryOffset, setMemoryOffset] = React.useState('1.05');
  const [memoryAllocated, setMemoryAllocated] = React.useState('0.5');

  const [vPCList, setVPCList] = React.useState([{ label: 'Loading', value: 'Loading' }]);
  const [vpc, setVpc] = React.useState('');

  const [cpuCnt, setCpuCnt] = React.useState('1');

  const [subnetList, setSubnetList] = React.useState([{ label: 'Select VPC', value: 'Select VPC' }]);
  const [subnet, setSubnet] = React.useState('please choose');

  const [storageSize, setStorageSize] = React.useState('10');
  const [storageClassList, setStorageClassList] = React.useState([{ label: 'Select storage class', value: 'Select storage class' }]);
  const [storageClass, setStorageClass] = React.useState('please choose');

  const [vmName, setVmName] = React.useState('new-vm-name');

  const [osList, setOSList] = React.useState([{ OS_NAME: 'Select OS', CLOUD_USER: 'Select OS' }]);
  const [os, setOS] = React.useState('please choose');


  const handleMemoryChange = (_event, memory: string) => {
    setMemory(memory);

    const offset = parseInt(memory) + 0.05;
    setMemoryOffset(offset.toString())

    const allocated = parseInt(memory) / 2;
    setMemoryAllocated(allocated.toString());
  };

  const handleVpcChange = (_event, vpc: string) => {
    setVpc(vpc);
    fetchSubnets(vpc);
  };

  const fetchSubnets = (vpc: string) => {
    fetch(`${backendURL}/api/create-vm/demo/subnets/` + vpc)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setSubnetList(data);
        const subnet = data[0].value;
        setSubnet(subnet);
        console.log('set subnets', data);
      })
  }

  const fetchStorageClasses = () => {
    fetch(`${backendURL}/api/create-vm/demo/storageclasses`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setStorageClassList(data);
        const storage = data[0].value;
        setStorageClass(storage);
        console.log('setStorageClassList', data);
      })
  }

  const fetchOSImages = () => {
    fetch(`${backendURL}/api/create-vm/demo/os-images`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setOSList(data);
        const os = data[0].value;
        setOS(os);
        console.log('setOSList', data);
      })
  }


  const handleCpuCntChange = (_event: React.FormEvent<HTMLSelectElement>, value: string) => {
    setCpuCnt(value);
  };

  const handleStorageSizeChange = (_event: React.FormEvent<HTMLInputElement>, value: string) => {
    setStorageSize(value);
  };

  const handleStorageClassChange = (_event: React.FormEvent<HTMLSelectElement>, value: string) => {
    setStorageClass(value);
  };

  const handleSubnetChange = (_event: React.FormEvent<HTMLSelectElement>, value: string) => {
    setSubnet(value);
  };



  React.useEffect(() => {
    fetch(`${backendURL}/api/create-vm/demo/vpclist`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setVPCList(data);
        const vpc = data[0].value;
        fetchSubnets(vpc);
        console.log('set vpc', vPCList);
      })

    fetchStorageClasses();
    fetchOSImages()

    return () => { };
  }, []);

  React.useEffect(() => {
    console.log('os changed', os);
    let osDefinition: any = osList[0];
    if (os) {
      osDefinition = osList.find((item) => { return item.CLOUD_USER === os; });
    }
    if (osDefinition) {
      setOsImageUrl(osDefinition.OS_IMAGE_URL);
      setCloudUser(osDefinition.CLOUD_USER);
      setCloudInit(osDefinition.CLOUD_INIT);
    }
  }, [os]);

  const vcpu_options = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' }
  ];


  const onEditorDidMount = (editor, monaco) => {
    editor.layout();
    editor.focus();
    monaco.editor.getModels()[0].updateOptions({ tabSize: 5 });
  };

  const onChange = (value) => {
    // eslint-disable-next-line no-console
    console.log(value);
  };

  const [activeTabKey, setActiveTabKey] = React.useState<string | number>(0);

  const handleTabClick = (
    event: React.MouseEvent<any> | React.KeyboardEvent | MouseEvent,
    tabIndex: string | number
  ) => {
    setActiveTabKey(tabIndex);
  };

  const createVM = async () => {
    let response = await fetch(`${backendURL}/api/create-vm/demo/datavolume/${vmName}`, {
      headers: {
        "Content-Type": "application/yaml",
      },
      method: "POST",
      body: dataVolumeYaml
    });
    console.log('datavolume: ', response);

    if (response.ok) {
      response = await fetch(`${backendURL}/api/create-vm/demo/vmi`, {
        headers: {
          "Content-Type": "application/yaml",
        },
        method: "POST",
        body: vmYaml
      });
      console.log('vm: ', response);

      if (response.ok) {
        setRefreshTree(refreshTree + 1);

        setViewIndex(VM_INDEX);

        const notification = `Virtual machine '${vmName}' successfully created. It is in provisioning state and it may take a while.`;
        const notifications_local = [notification, ...notifications];
        setNotifications(notifications_local);

        setIsModalOpen(false);
      }
    }


  }

  const dataVolumeYaml = `apiVersion: cdi.kubevirt.io/v1beta1
kind: DataVolume
metadata:
  name: ${vmName}-root
spec:
  pvc:
    accessModes:
    - ReadWriteOnce
    resources:
      requests:
        storage: ${storageSize}G
    storageClassName: ${storageClass}
  source:
    http:
      url: ${osImageUrl}`;

  const vmYaml = `apiVersion: kubevirt.io/v1
kind: VirtualMachine
metadata:
  name: ${vmName}
  namespace: ${namespace}
spec:
  running: true
  template:
    spec:
      networks:
      - name: vpc_net_0
        multus:
          default: true
          networkName: ${namespace}/ovn-${namespace}
      domain:
        devices:
          interfaces:
            - name: vpc_net_0
              bridge: {}
          disks:
          - disk: 
              bus: virtio
            name: root-volume
          - name: cloudinitdisk
            disk:
              bus: virtio
        machine:
          type: ""
        cpu:
          cores: ${cpuCnt}
        memory:
          guest: ${memory}G
      readinessProbe:
        guestAgentPing: {}
        failureThreshold: 10
        initialDelaySeconds: 30
        periodSeconds: 10
        timeoutSeconds: 5
      accessCredentials:
      - sshPublicKey:
          source:
            secret:
              secretName: my-pub-key
          propagationMethod:
            qemuGuestAgent:
              users:
              - ${cloudUser}
      terminationGracePeriodSeconds: 60
      volumes:
      - dataVolume:
          name: ${vmName}-root
        name: root-volume
      - name: cloudinitdisk
        cloudInitNoCloud:
          userData: |-
            ${cloudInit}`;

  return (
    <Modal
      isOpen={isModalOpen}
      showClose={false}
      aria-label="Create VM"
      hasNoBodyWrapper
      onEscapePress={() => setIsModalOpen(false)}
      variant={ModalVariant.medium}
    >
      <Wizard
        height={400}
        onClose={() => setIsModalOpen(false)}
        title="Specify params"
        header={
          <WizardHeader
            onClose={() => setIsModalOpen(false)}
            title="Create VM"
            description="Specify params"
          />
        }
      >
        <WizardStep name="Step 1" id="in-modal-step-1">


          <div className='advanced-options'>

            <div>
              <VirtualMachineIcon></VirtualMachineIcon> <span className='bold-text'>VM Name</span> <span className='required-selection-os'>*</span><br />
              <TextInput
                placeholder=""
                value={vmName}
                type="text"
                onChange={(_event, vmName) => setVmName(vmName)}
                aria-label="text input example"
              />
            </div>
          </div>

          <ChangeOS osList={osList} os={os} setOS={setOS} osImageUrl={osImageUrl} setOsImageUrl={setOsImageUrl}></ChangeOS>

          <div className='title-padding'>
            <MicrochipIcon></MicrochipIcon> <span className='bold-text'>Number of vCPU's</span> <span className='required-selection-os'>*</span><br />
            <FormSelect
              value={cpuCnt}
              onChange={handleCpuCntChange}
              id="horizontal-form-title"
              name="horizontal-form-title"
              aria-label="Select VM image"
            >
              {vcpu_options.map((option, index) => (
                <FormSelectOption key={index} value={option.value} label={option.label} />
              ))}
            </FormSelect>
          </div>

          <div className='title-padding'>
            <MemoryIcon></MemoryIcon> <span className='bold-text'>Number of RAM (GB)</span> <span className='required-selection-os'>*</span><br />
            <TextInput
              isRequired
              type="text"
              id="simple-form-name-01"
              name="simple-form-name-01"
              aria-describedby="simple-form-name-01-helper"
              value={memory}
              onChange={handleMemoryChange}
            />
          </div>

          <div className='title-padding'>
            <CloudTenantIcon></CloudTenantIcon> <span className='bold-text'>VPC</span> <span className='required-selection-os'>*</span><br />
            <FormSelect
              value={vpc}
              onChange={handleVpcChange}
              id="horizontal-form-title"
              name="horizontal-form-title"
              aria-label="Select VPC"
            >
              {vPCList.map((option, index) => (
                <FormSelectOption key={index} value={option.value} label={option.label} />
              ))}
            </FormSelect>
          </div>

          <div className='title-padding'>
            <NetworkIcon></NetworkIcon> <span className='bold-text'>Subnet</span> <span className='required-selection-os'>*</span><br />

            <FormSelect
              value={subnet}
              onChange={handleSubnetChange}
              id="horizontal-form-title"
              name="horizontal-form-title"
              aria-label="Select VM image"
            >
              {subnetList.map((option, index) => (
                <FormSelectOption key={index} value={option.value} label={option.label} />
              ))}
            </FormSelect>
          </div>

          <div className='title-padding'>
            <DatabaseIcon></DatabaseIcon> <span className='bold-text'>Root Storage size (GB)</span> <span className='required-selection-os'>*</span><br />

            <TextInput
              isRequired
              type="text"
              id="simple-form-name-01"
              name="simple-form-name-01"
              aria-describedby="simple-form-name-01-helper"
              value={storageSize}
              onChange={handleStorageSizeChange}
            />
          </div>

          <div className='title-padding'>
            <VolumeIcon></VolumeIcon> <span className='bold-text'>Root storage type</span> <span className='required-selection-os'>*</span><br />

            <FormSelect
              value={storageClass}
              onChange={handleStorageClassChange}
              id="horizontal-form-title"
              name="horizontal-form-title"
              aria-label="Select VM image"
            >
              {storageClassList.map((option, index) => (
                <FormSelectOption key={index} value={option.value} label={option.label} />
              ))}
            </FormSelect>
          </div>

        </WizardStep>
        <WizardStep
          name="Review"
          id="in-modal-review-step"
          footer={{
            nextButtonText: 'Finish', onNext: () => {
              createVM();
            }
          }}
        >
          <Tabs
            activeKey={activeTabKey}
            onSelect={handleTabClick}
            aria-label="Tabs in the example with a tooltip ref"
            role="region"
          >

            <Tab eventKey={0} title={<TabTitleText>VM config</TabTitleText>}>
              <CodeEditor
                isDarkTheme={true}
                isLineNumbersVisible={true}
                isReadOnly={false}
                isMinimapVisible={false}
                code={vmYaml}
                onChange={onChange}
                language={Language.yaml}
                onEditorDidMount={onEditorDidMount}
                height="500px"
              />
            </Tab>


            <Tab eventKey={1} title={<TabTitleText>Data Volume</TabTitleText>} aria-label="Tooltip ref content - users">
              <CodeEditor
                isDarkTheme={true}
                isLineNumbersVisible={true}
                isReadOnly={false}
                isMinimapVisible={false}
                code={dataVolumeYaml}
                onChange={onChange}
                language={Language.yaml}
                onEditorDidMount={onEditorDidMount}
                height="500px"
              />
            </Tab>

          </Tabs>
        </WizardStep>
      </Wizard>
    </Modal>

  )
}

export { AddNewVMModal };
