import * as React from 'react';
import { PageSection, TreeViewDataItem } from '@patternfly/react-core';
import { Sidebar } from '@app/TreeViewImpl/Sidebar';
import { VirtualMachineInstance } from './VirtualMachineInstance/VirtualMachineInstance';
import { ProjectView } from './ProjectView';
import { VirtualMachinesView } from './VirtualMachinesView';
import { Pod, emptyPod, parseVM } from './VMParser';
import { backendURL } from './BackendURL';
import { DataContext } from '@app/DataContext';
import { useContext } from 'react';
import { SystemPodView } from '@app/TreeViewImpl/SystemPodView';
import { SystemServiceView } from '@app/TreeViewImpl/SystemServiceView';
import { SystemDeploymentView } from '@app/TreeViewImpl/SystemDeploymentView';
import { SystemJobView } from '@app/TreeViewImpl/SystemJobView';
import { SystemDaemonSetView } from '@app/TreeViewImpl/SystemDaemonSetView';
import { SystemStatefulSetView } from '@app/TreeViewImpl/SystemStatefulSetView';
import { SystemReplicaSetView } from '@app/TreeViewImpl/SystemReplicaSetView';
import { SystemIngressView } from '@app/TreeViewImpl/SystemIngressView';


const TreeContainer: React.FunctionComponent = () => {
  const [openedShells, setOpenedShells] = React.useState<any>({});
  const [item, setItem] = React.useState<Pod>();
  const [imageUrl, setImageUrl] = React.useState<string>();
  const [activeItem, setActiveItem] = React.useState<TreeViewDataItem>();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [iframeUrl, setIframeUrl] = React.useState<string>("");
  const [currentProgress, setCurrentProgress] = React.useState<number>(0);
  const [isLoadingSsh, setIsLoadingSsh] = React.useState<boolean>(false);

  const VMI_INDEX = 1;
  const VM_INDEX = 2;
  const NAMESPACE_INDEX = 3;
  const K8S_INDEX = 4;
  const K8S_PROJECT_INDEX = 5;

  const VIRTUAL_MACHINES_ID: number = 2222;
  const NAMESPACE_ID: number = 1111;
  const K8S_ID: number = 3333;
  const K8S_ID_PROJECT: number = 4444;

  const CRON_JOBS = 1981;
  const DAEMON_SETS = 1982;
  const DEPLOYMENTS = 1983;
  const JOBS = 1984;
  const PODS = 1985;
  const REPLICA_SETS = 1986;
  const REPLICATION_CONTROLLERS = 1987;
  const STATEFUL_SETS = 1988;

  const POD_AUTOSCALING = 1990;
  const INGRESSES = 1991;
  const SERVICES = 1992;

  const CONFIG_MAPS = 1994;
  const PVC = 1995;
  const SECRETS = 1996;
  const SERVICE_ACCOUNTS = 1997;

  const { viewIndex, setViewIndex } = useContext(DataContext);
  const { refreshTree, setRefreshTree } = useContext(DataContext);


  React.useEffect(() => {
    if (!activeItem) return;

    const url = `${backendURL}/api/virtual-machines/demo/vmi/` + showName(activeItem.name);
    const response = fetch(url)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        const skipMetrics = false;
        const item = parseVM(data, skipMetrics);
        setItem(item);
      })
  }, [refreshTree])


  React.useEffect(() => {
    console.log(activeItem, '- Has changed')

    setIframeUrl('');
    setCurrentProgress(0);
    setIsLoadingSsh(false);

    const empty_pod: Pod = emptyPod();
    setItem(empty_pod);
    setImageUrl("");

    if (!activeItem) return;

    const _image_url = `${backendURL}/apis/subresources.kubevirt.io/v1alpha3/namespaces/demo/virtualmachineinstances/` + showName(activeItem.name) + '/vnc/screenshot';
    setImageUrl(_image_url);
    const url = `${backendURL}/api/virtual-machines/demo/vmi/` + showName(activeItem.name);
    const response = fetch(url)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        const skipMetrics = false;
        const item = parseVM(data, skipMetrics);
        setItem(item);
      })

  }, [activeItem])

  const onSelect = (_event: React.MouseEvent, treeViewItem: TreeViewDataItem) => {
    console.log('onSelect2');
    const id = parseInt(treeViewItem.id || '0');

    if (id === VIRTUAL_MACHINES_ID) {
      setViewIndex(VM_INDEX);
    }
    if (id === NAMESPACE_ID) {
      setViewIndex(NAMESPACE_INDEX);
    }
    if (id === K8S_ID) {
      setViewIndex(K8S_INDEX);
    }
    if (id >= K8S_ID_PROJECT && id < 5000) {
      setViewIndex(K8S_PROJECT_INDEX);
    }
    if (id === CRON_JOBS) {
      setViewIndex(CRON_JOBS);
      return
    }

    if (id === DAEMON_SETS) {
      setViewIndex(DAEMON_SETS);
      return
    }

    if (id === DEPLOYMENTS) {
      setViewIndex(DEPLOYMENTS);
      return
    }


    if (id === JOBS) {
      setViewIndex(JOBS);
      return
    }

    if (id === PODS) {
      setViewIndex(PODS);
      return
    }

    if (id === REPLICA_SETS) {
      setViewIndex(REPLICA_SETS);
      return
    }

    if (id === REPLICATION_CONTROLLERS) {
      setViewIndex(REPLICATION_CONTROLLERS);
      return
    }

    if (id === STATEFUL_SETS) {
      setViewIndex(STATEFUL_SETS);
      return
    }

    if (id === POD_AUTOSCALING) {
      setViewIndex(POD_AUTOSCALING);
      return
    }

    if (id === INGRESSES) {
      setViewIndex(INGRESSES);
      return
    }

    if (id === SERVICES) {
      setViewIndex(SERVICES);
      return
    }

    if (id === CONFIG_MAPS) {
      setViewIndex(CONFIG_MAPS);
      return
    }

    if (id === PVC) {
      setViewIndex(PVC);
      return
    }

    if (id === SECRETS) {
      setViewIndex(SECRETS);
      return
    }

    if (id === SERVICE_ACCOUNTS) {
      setViewIndex(SERVICE_ACCOUNTS);
      return
    }


    if (treeViewItem && !treeViewItem.children) {
      setViewIndex(VMI_INDEX);
      setActiveItem(treeViewItem);
      console.log('onSelect3')
    }
  };

  const showName = (name) => {
    return name.replace(" ", "").replace("⏵", "").replace("⏹", "").replace("⚠", "").replace("⏸", "");
  }

  return (
    <PageSection>
      <div className="full-page">
        <div className="sidebar">
          <div className="sidebar-overflow">
            <Sidebar onSelect={onSelect} />
          </div>
        </div>
        <div className="fullpage-content">

          {viewIndex === VMI_INDEX && <VirtualMachineInstance
            item={item}
            imageUrl={imageUrl}
            openedShells={openedShells}
            setOpenedShells={setOpenedShells}
            iframeUrl={iframeUrl}
            setIframeUrl={setIframeUrl}
            currentProgress={currentProgress}
            setCurrentProgress={setCurrentProgress}
            isLoadingSsh={isLoadingSsh}
            setIsLoadingSsh={setIsLoadingSsh}
            activeItem={activeItem}
          ></VirtualMachineInstance>}

          {viewIndex === VM_INDEX && <React.Fragment><VirtualMachinesView></VirtualMachinesView></React.Fragment>}
          {viewIndex === NAMESPACE_INDEX && <React.Fragment><ProjectView></ProjectView></React.Fragment>}
          {viewIndex === K8S_INDEX && <React.Fragment>K8S</React.Fragment>}
          {viewIndex === K8S_PROJECT_INDEX && <React.Fragment>K8S Project</React.Fragment>}

          {viewIndex === CRON_JOBS && <React.Fragment>CRON_JOBS</React.Fragment>}
          {viewIndex === DAEMON_SETS && <React.Fragment><SystemDaemonSetView></SystemDaemonSetView></React.Fragment>}
          {viewIndex === DEPLOYMENTS && <React.Fragment><SystemDeploymentView></SystemDeploymentView></React.Fragment>}
          {viewIndex === JOBS && <React.Fragment><SystemJobView></SystemJobView></React.Fragment>}
          {viewIndex === PODS && <React.Fragment><SystemPodView></SystemPodView></React.Fragment>}
          {viewIndex === REPLICA_SETS && <React.Fragment><SystemReplicaSetView></SystemReplicaSetView></React.Fragment>}
          {viewIndex === REPLICATION_CONTROLLERS && <React.Fragment>REPLICATION_CONTROLLERS</React.Fragment>}
          {viewIndex === STATEFUL_SETS && <React.Fragment><SystemStatefulSetView></SystemStatefulSetView></React.Fragment>}

          {viewIndex === POD_AUTOSCALING && <React.Fragment>POD_AUTOSCALING</React.Fragment>}
          {viewIndex === INGRESSES && <React.Fragment><SystemIngressView></SystemIngressView></React.Fragment>}
          {viewIndex === SERVICES && <React.Fragment><SystemServiceView></SystemServiceView></React.Fragment>}

          {viewIndex === CONFIG_MAPS && <React.Fragment>CONFIG_MAPS</React.Fragment>}
          {viewIndex === PVC && <React.Fragment>PVC</React.Fragment>}
          {viewIndex === SECRETS && <React.Fragment>SECRETS</React.Fragment>}
          {viewIndex === SERVICE_ACCOUNTS && <React.Fragment>SERVICE_ACCOUNTS</React.Fragment>}




        </div>



      </div>
    </PageSection>
  )
}

export { TreeContainer };
